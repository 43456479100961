import React from 'react'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Wrapper from '../common/Wrapper'
import { colors } from 'Theme/theme'

const useStyles = makeStyles({
  container: {},
  headline: {
    color: 'rgba(0, 44, 56, 0.87)',
    paddingBottom: '5px',
    fontsize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: '700',
    lineHeight: '28px'
  },
  text: {
    color: colors.gray,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  title: {
    color: 'rgba(0, 44, 56, 0.87)',
    fontSize: '20px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: '600',
    lineHeight: '31px',
    letterSpacing: '0.15px'
  },
  subtitle: {
    color: 'rgba(0, 44, 56, 0.87)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    height: 'auto'
  }
})

export interface Props {
  /**
   * What headline to use
   */
  headline?: string
  /**
   * What title to use
   */
  title?: string
  /**
   * What subtitle to use
   */
  subtitle?: string
  /**
   * What body to use
   */
  body?: string
  /**
   * What align to use
   */
  align?: 'left' | 'right' | 'center' | 'justify'
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const Heading: React.FC<Props> = ({
  headline,
  title,
  subtitle,
  body,
  align = 'center',
  style,
  maxWidth
}) => {
  const classes = useStyles()

  return (
    <Wrapper style={style} maxWidth={maxWidth}>
      <div style={{ textAlign: align, paddingBottom: '16px' }}>
        {headline ? (
          <Typography variant="h5" className={classes.headline} sx={{ paddingBottom: '6px' }}>
            {headline}
          </Typography>
        ) : null}
        {title ? (
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        ) : null}
        {subtitle ? (
          <Typography variant="subtitle1" className={classes.subtitle}>
            {subtitle}
          </Typography>
        ) : null}
        {body ? (
          <Typography variant="body2" className={classes.text}>
            {body}
          </Typography>
        ) : null}
      </div>
    </Wrapper>
  )
}

export default Heading
