import { createSlice } from '@reduxjs/toolkit'

const repairTroubleshootingBase = {
  list: [],
  customDetails: ''
}

export const motorBase: Motor = {
  manufacturer: '',
  model: '',
  hours: '',
  year: '',
  serial: '',
  side: undefined,
  additionalDetails: '',
  customMotorDetails: '',
  services: [],
  repairTroubleshooting: repairTroubleshootingBase
}
export type Model = {
  model: string
  brand: string
}
export interface MaintenanceService {
  id: string
  serviceName: string
  serviceType: string
  price: number
  sku: string
  model: string
  brand: string
  createdTime: string
  reservationDurationHours: string
}
export interface Service {
  serviceId: string
  sku: string
  category: string
  subCategory: string
  label: string
  description: string
  priceWithVat: number
  vat: number
  priceWithoutVat: number
  vatAmount: number
}

export type Motor = {
  manufacturer: string
  model: string
  hours: string
  year: string
  serial: string
  side: 'left' | 'center' | 'right' | undefined
  additionalDetails: string
  customMotorDetails: string
  services: Array<string>
  repairTroubleshooting: { list: Array<string>; customDetails: string }
}
type ContactInfo = {
  firstName: string
  lastName: string
  phone: string
  email: string
  address: string
  zipCode: string
  city: string
}
type ManufacturerAndModelObject = {
  [manufacturer: string]: Array<string>
}
type MaintenanceServiceAirtableType = {
  id: string
  fields: {
    'Service Name': string
    'Service Type': string
    Price: number
    SKU: string
    Model: string
    Brand: string
    'Reservation Duration Hours': string
  }
  createdTime: '2022-02-14T13:11:32.000Z'
  maintenanceService: string
}
type AdditionalServiceAirtableType = {
  id: string
  fields: {
    Tarkenne?: string
    Lisäpalvelu?: string
  }
  createdTime: string
}
type AdditionalService = {
  id: string
  serviceName: string
}
type MaintenanceSlot = {
  [date: string]: boolean
}
type Maintenance = {
  availableMaintenanceServices: Array<MaintenanceService>
  availableAdditionalServices: Array<AdditionalService>
  manufacturersAndModels: ManufacturerAndModelObject
  modelList: Array<string>
  openMaintenanceSlots: MaintenanceSlot
  maintenanceFlow: {
    otherManufacturerOpen: boolean
    otherModelOpen: boolean
    fromSummary: boolean
  }
  contactInfo: ContactInfo
  launchService: boolean
  scheduledMaintenance: boolean
  motors: Array<Motor>
  currentlySelectedMotor: number
  maintenanceDate: Date | null
}

export const initialState: Maintenance = {
  availableMaintenanceServices: [],
  availableAdditionalServices: [],
  manufacturersAndModels: {},
  modelList: [],
  openMaintenanceSlots: {},
  maintenanceFlow: {
    otherManufacturerOpen: false,
    otherModelOpen: false,
    fromSummary: false
  },
  contactInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    zipCode: '',
    city: ''
  },
  launchService: false,
  scheduledMaintenance: false,
  motors: [motorBase],
  currentlySelectedMotor: 0,
  maintenanceDate: new Date()
}

const maintenanceSlice = createSlice({
  name: 'maintenanceSlice',
  initialState,
  reducers: {
    initMaintenanceServices(state, { payload }) {
      const maintenanceServices: MaintenanceService[] = payload.map(
        (maintenanceService: MaintenanceServiceAirtableType) => {
          return {
            id: maintenanceService['id'],
            serviceName: maintenanceService.fields['Service Name'],
            serviceType: maintenanceService.fields['Service Type'],
            price: maintenanceService.fields['Price'],
            sku: maintenanceService.fields['SKU'],
            model: maintenanceService.fields['Model'],
            brand: maintenanceService.fields['Brand'],
            createdTime: maintenanceService['createdTime'],
            reservationDurationHours: maintenanceService.fields['Reservation Duration Hours']
          }
        }
      )
      return {
        ...state,
        availableMaintenanceServices: maintenanceServices
      }
    },
    initAdditionalServices(state, { payload }) {
      const additionalServices: AdditionalService[] = payload.map(
        (additionalService: AdditionalServiceAirtableType) => {
          return {
            id: additionalService.id,
            serviceName: additionalService.fields['Tarkenne']
          }
        }
      )
      return {
        ...state,
        availableAdditionalServices: additionalServices
      }
    },
    initManufacturersAndModels(state, { payload }) {
      return {
        ...state,
        manufacturersAndModels: payload
      }
    },
    initModelList(state, { payload }) {
      return {
        ...state,
        modelList: payload
      }
    },
    changeMaintenanceDetails(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    },
    changeMotorDetails(state, { payload }) {
      return {
        ...state,
        motors: state.motors.map((motor, index) =>
          state.currentlySelectedMotor === index ? { ...motor, ...payload } : motor
        )
      }
    },
    addNewMotor(state) {
      state.motors.push(motorBase)
    },
    deleteOnlyMotor(state) {
      return {
        ...state,
        currentlySelectedMotor: 0, // Deleting currently selected motor? No problem, I got this
        motors: [motorBase]
      }
    },
    deleteMotor(state, { payload }) {
      return {
        ...state,
        currentlySelectedMotor: 0, // Deleting currently selected motor? No problem, I got this
        motors: [...state.motors.slice(0, payload), ...state.motors.slice(payload + 1)]
      }
    },
    deleteService(state, { payload }) {
      return {
        ...state,
        motors: state.motors.map((motor, index) =>
          payload.motorIndex === index
            ? {
                ...motor,
                services: [
                  ...state.motors[payload.motorIndex].services.slice(0, payload.serviceIndex),
                  ...state.motors[payload.motorIndex].services.slice(payload.serviceIndex + 1)
                ]
              }
            : motor
        )
      }
    },
    deleteRepairService(state, { payload }) {
      return {
        ...state,
        motors: state.motors.map((motor, index) =>
          payload.motorIndex === index
            ? {
                ...motor,
                services: [
                  ...state.motors[payload.motorIndex].services.slice(0, payload.serviceIndex),
                  ...state.motors[payload.motorIndex].services.slice(payload.serviceIndex + 1)
                ],
                repairTroubleshooting: repairTroubleshootingBase
              }
            : motor
        )
      }
    },
    copyMotor(state, { payload }) {
      const duplicatedMotor = state.motors[payload]
      state.motors.push(duplicatedMotor)
    },
    getServiceDates(state, { payload }) {
      return {
        ...state,
        openMaintenanceSlots: payload
      }
    },
    resetState() {
      return initialState
    }
  }
})

export const {
  changeMaintenanceDetails,
  changeMotorDetails,
  addNewMotor,
  deleteMotor,
  deleteOnlyMotor,
  deleteService,
  deleteRepairService,
  copyMotor,
  initMaintenanceServices,
  initManufacturersAndModels,
  initAdditionalServices,
  getServiceDates,
  resetState,
  initModelList
} = maintenanceSlice.actions
export default maintenanceSlice.reducer
