import { ApolloClient, InMemoryCache, from, createHttpLink } from '@apollo/client'

import { setContext } from '@apollo/client/link/context'

export const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Organization': process.env.REACT_APP_ORGANIZATION
    }
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLURL
})

const Client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache()
})

export default Client
