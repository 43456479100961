import { MaintenanceService, Motor } from 'Features/Maintenance/maintenanceSlice'
import { findSelectedService, findUnitServices } from '.'

type CalculateServiceDurationPropsType = {
  motors: Array<Motor>
  availableMaintenanceServices: Array<MaintenanceService>
}

export const calculateServiceDuration = ({
  motors,
  availableMaintenanceServices
}: CalculateServiceDurationPropsType) => {
  const motorServiceDurations = motors.map(motor => {
    const motorServices = findUnitServices(availableMaintenanceServices, motor.model)
    const selectedServices = motor.services.map(service => {
      const selectedService = findSelectedService({
        availableMaintenanceServices: motorServices,
        serviceId: service
      })
      return selectedService
    })

    const serviceDurations = selectedServices.map(service => {
      if (service?.reservationDurationHours) {
        return parseFloat(service?.reservationDurationHours.replace(',', '.'))
      } else {
        return 0
      }
    })
    return serviceDurations
  })
  const totalDuration = motorServiceDurations
    .map(motorServiceDuration => motorServiceDuration.reduce((partialSum, a) => partialSum + a, 0))
    .reduce((partialSum, a) => partialSum + a, 0)

  return totalDuration
}
