import { Layout } from '@uniqore/module'
import RadioButtonGroup, { RadioButtonGroupSchema } from '@uniqore/module/RadioButtonGroup'
import { AltButton } from 'Components/AltButton'
import { AppBar } from 'Components/AppBar'
import { Heading } from 'Components/Heading'
import { changeMotorDetails, motorBase } from 'Features/Maintenance/maintenanceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'

const SelectModel = () => {
  const { currentlySelectedMotor, manufacturersAndModels } = useSelector(
    (state: RootState) => state.maintenance
  )
  const { manufacturer, model } = useSelector(
    (state: RootState) => state.maintenance.motors[currentlySelectedMotor]
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const handleChange = (value: string) => {
    if (value !== model) {
      dispatch(changeMotorDetails({ ...motorBase, manufacturer, model: value || '' }))
    } else {
      dispatch(changeMotorDetails({ model: value }))
    }
    history.push('/details')
  }

  const selectedManufacturersModels = manufacturersAndModels[manufacturer]?.slice()?.sort()
  const radioButtons: RadioButtonGroupSchema[] = selectedManufacturersModels?.map(model => {
    return {
      text: model,
      value: model
    }
  })
  return (
    <Layout top={<AppBar text="Valmistaja" />}>
      <Heading title="Valitse malli" subtitle={`Valittu valmistaja: ${manufacturer}`} />
      <RadioButtonGroup value={model} onChange={handleChange} radioButtons={radioButtons} />
      <AltButton
        text="Jokin muu, mikä?"
        onClickEvent={() => history.push('/custom')}
        style={{ marginTop: 4 }}
      />
    </Layout>
  )
}

export default SelectModel
