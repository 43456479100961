import { Button as MuiButton, Typography } from '@mui/material'
import { colors } from 'Theme/theme'

type ButtonProps = {
  text: string
  onClickEvent: () => void
  style?: React.CSSProperties
  disabled?: boolean
}
const Button = ({ text, onClickEvent, style, disabled }: ButtonProps) => {
  return (
    <MuiButton
      onClick={() => onClickEvent()}
      style={style}
      disabled={disabled}
      sx={{
        height: '41px',
        borderRadius: '100px',
        width: '100%',
        padding: '0 26px',
        backgroundColor: disabled ? colors.lightGray : colors.mainBlue,
        '&:hover': {
          backgroundColor: colors.mainBlue
        }
      }}
    >
      <Typography sx={{ fontSize: '14px' }} color={colors.white}>
        {text}
      </Typography>
    </MuiButton>
  )
}

export default Button
