import { AppBar } from 'Components/AppBar'
import { calculateServiceDuration } from 'Components/common'
import { Heading } from 'Components/Heading'
import { useHistory } from 'react-router-dom'
import { LocalizationProvider, StaticDatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { fi } from 'date-fns/locale'
import { CircularProgress, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { changeMaintenanceDetails } from 'Features/Maintenance/maintenanceSlice'
import { Button } from 'Components/Button'
import { format } from 'date-fns'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import Client from 'api/client'
import { Layout } from '@uniqore/module'

export const INGEST_FORM = gql`
  mutation ingestForm($id: ID!, $form: GenericScalar!) {
    ingestForm(id: $id, form: $form) {
      ok
      result
      errors
    }
  }
`

export const FORM_RESULT = gql`
  query formResult($id: ID!) {
    formResult(id: $id) {
      result
      state
    }
  }
`

const Datepicker = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [freeDates, setFreeDates] = useState<{ [date: string]: boolean }>({})
  const {
    maintenanceDate,
    maintenanceFlow: { fromSummary },
    motors,
    availableMaintenanceServices
  } = useSelector((state: RootState) => state.maintenance)

  const maxRetries = 20

  const workDuration = (
    calculateServiceDuration({ motors, availableMaintenanceServices }) * 60
  ).toFixed()

  useEffect(() => {
    if (Object.keys(freeDates).length === 0 && workDuration) {
      fetchFreeWorkslots()
    }
  }, [freeDates, workDuration])
  const getFreeWorkslots = async (formId: string, i: number) => {
    const result = await Client.query({
      fetchPolicy: 'no-cache',
      query: FORM_RESULT,
      variables: {
        id: formId
      }
    })

    const freeSlots =
      result.data.formResult && result.data.formResult.state == 'SUCCESS'
        ? result.data.formResult.result.isDayFree
        : null
    if (freeSlots) {
      setFreeDates(freeSlots)
    }
    if (!freeSlots || Object.entries(freeSlots).length === 0) {
      setTimeout(() => {
        if (--i) getFreeWorkslots(formId, i)
      }, 500)
      return
    }
  }

  const [createFreeworkslotSession] = useMutation(INGEST_FORM, {
    client: Client,
    onCompleted(mutationResult) {
      const formId = mutationResult.ingestForm.result
      if (formId) {
        getFreeWorkslots(formId, maxRetries)
      }
    }
  })
  const fetchFreeWorkslots = async () => {
    await createFreeworkslotSession({
      variables: {
        id: process.env.REACT_APP_FETCH_OPEN_DATES,
        form: {
          workDuration: workDuration
        }
      }
    })
  }

  const handleChange = (date: Date | null) => {
    dispatch(changeMaintenanceDetails({ maintenanceDate: date }))
  }

  const nextPage = (location: string) => {
    if (fromSummary) {
      dispatch(
        changeMaintenanceDetails({
          maintenanceFlow: { fromSummary: false }
        })
      )
      history.push('ordersummary')
    } else {
      history.push(`${location}`)
    }
  }

  function disableDates(date: Date) {
    const formattedDate = format(new Date(date), 'yyyy-MM-dd')
    if (freeDates[formattedDate]) {
      return false
    }
    return true
  }

  return (
    <Layout
      top={<AppBar text="Yhteenveto palveluista" />}
      bottom={<Button text="Yhteystietoihin" onClickEvent={() => nextPage('contact')} />}
    >
      <Heading
        title="Huoltopäivä"
        subtitle={`Huollettava laite tuodaan viimeistään huoltopäivän aamuna klo 9. Huollon arvioitu kesto tarkennetaan tilausvahvistuksen yhteydessä.`}
      />
      {Object.keys(freeDates).length === 0 ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            zIndex: 2000
          }}
        />
      ) : (
        <LocalizationProvider locale={fi} dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            disablePast
            views={['day']}
            shouldDisableDate={disableDates}
            displayStaticWrapperAs="desktop"
            value={maintenanceDate}
            onChange={handleChange}
            renderInput={params => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    </Layout>
  )
}

export default Datepicker
