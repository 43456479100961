import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { colors } from 'Theme/theme'

const Circle = styled.div`
  background-color: ${colors.mainBlack};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CircleContainer = styled.div`
  width: 56px;
`
type TextCircleProps = {
  text?: string | number
}
const TextCircle = ({ text }: TextCircleProps) => {
  return (
    <CircleContainer>
      <Circle>
        <Typography color={colors.white}>{text}</Typography>
      </Circle>
    </CircleContainer>
  )
}

export default TextCircle
