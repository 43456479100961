import * as React from 'react'
import { AppBar as MuiAppBar } from '@mui/material'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { ArrowBack } from '@mui/icons-material'
import { colors } from 'Theme/theme'
import { useHistory } from 'react-router-dom'

type AppBarProps = {
  text?: string
  rightIcon?: React.ReactNode
}

export default function AppBar({ text, rightIcon }: AppBarProps) {
  const history = useHistory()
  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: colors.transparent, color: colors.mainBlack }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => history.goBack()}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
            {text}
          </Typography>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
            {rightIcon}
          </IconButton>
        </Toolbar>
      </MuiAppBar>
    </Box>
  )
}
