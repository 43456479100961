import { MoreVert } from '@mui/icons-material'
import { Container, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { SummaryFieldsContainer, TextCircle } from 'Components/common'
import {
  changeMaintenanceDetails,
  copyMotor,
  deleteMotor,
  deleteOnlyMotor
} from 'Features/Maintenance/maintenanceSlice'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'

type EngineSummaryProps = {
  engineSummary?: { manufacturer: string; model: string; hours: string; year: string }
  index: number
}

const MotorBlock = ({ engineSummary, index }: EngineSummaryProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { motors } = useSelector((state: RootState) => state.maintenance)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const deleteMotorHandler = () => {
    if (motors.length === 1) {
      dispatch(deleteOnlyMotor())
      handleClose()
      history.push('manufacturer')
    } else {
      dispatch(deleteMotor(index))
      handleClose()
    }
  }
  const editMotorHandler = () => {
    dispatch(changeMaintenanceDetails({ currentlySelectedMotor: index }))
    handleClose()
    history.push('manufacturer')
  }
  const copyMotorHandler = () => {
    dispatch(copyMotor(index))
    handleClose()
  }

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: 0,
        paddingRight: 0
      }}
      disableGutters
    >
      <TextCircle text={engineSummary?.manufacturer.substring(0, 2) || ''} />
      <SummaryFieldsContainer>
        <Typography>{`${engineSummary?.manufacturer} ${engineSummary?.model}`}</Typography>
        <Typography variant="caption">{`Ajotunnit: ${engineSummary?.hours}`}</Typography>
        <Typography variant="caption">{`Vuosimalli: ${engineSummary?.year}`}</Typography>
      </SummaryFieldsContainer>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2, marginRight: 0 }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          <MenuItem onClick={copyMotorHandler}>Kopioi</MenuItem>
          <MenuItem onClick={editMotorHandler}>Muokkaa</MenuItem>
          <MenuItem onClick={deleteMotorHandler}>Poista</MenuItem>
        </Menu>
      </div>
    </Container>
  )
}

export default MotorBlock
