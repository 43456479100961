import { MaintenanceService } from 'Features/Maintenance/maintenanceSlice'

type FindSelectedServiceProps = {
  availableMaintenanceServices: Array<MaintenanceService>
  serviceId: string
}

export const findSelectedService = ({
  availableMaintenanceServices,
  serviceId
}: FindSelectedServiceProps) => {
  if (serviceId === 'repair') {
    return { serviceName: 'repair', price: 80 } as MaintenanceService
  } else {
    const serviceDetails = availableMaintenanceServices.find(
      availableService => availableService.id === serviceId
    )
    return serviceDetails
  }
}
