import { Delete } from '@mui/icons-material'
import { Container, IconButton, Typography } from '@mui/material'
import {
  ConfirmationModal,
  findSelectedService,
  findUnitServices,
  SummaryFieldsContainer,
  TextCircle
} from 'Components/common'
import { deleteRepairService, deleteService } from 'Features/Maintenance/maintenanceSlice'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'

type ServiceSummaryProps = {
  serviceId: string
  motorIndex: number
  index: number
}

const ServiceBlock = ({ serviceId, motorIndex, index }: ServiceSummaryProps) => {
  const dispatch = useDispatch()
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const deleteServiceHandler = () => {
    if (serviceId !== 'repair') {
      dispatch(deleteService({ motorIndex, serviceIndex: index }))
    } else {
      dispatch(deleteRepairService({ motorIndex, serviceIndex: index }))
    }
    setConfirmationOpen(false)
  }

  const { availableMaintenanceServices } = useSelector((state: RootState) => state.maintenance)
  const { repairTroubleshooting, model } = useSelector(
    (state: RootState) => state.maintenance.motors[motorIndex]
  )

  const serviceDetails = findUnitServices(availableMaintenanceServices, model)

  const selectedService = findSelectedService({
    availableMaintenanceServices: serviceDetails,
    serviceId
  })
  const priceType = serviceId === 'repair' ? '€ / h' : '€'
  const serviceName =
    selectedService?.serviceName === 'repair'
      ? `${repairTroubleshooting.list.concat()}, ${repairTroubleshooting?.customDetails} `
      : selectedService?.serviceName

  return (
    <Container
      sx={{ display: 'flex', alignItems: 'center', paddingTop: '8px', paddingBottom: '8px' }}
      disableGutters
    >
      {confirmationOpen ? (
        <ConfirmationModal
          title="Oletko varma, että haluat poistaa valitun palvelun?"
          subtitle="Jokaista moottoria kohden täytyy olla valittuna vähintään yksi palvelu"
          closeModal={() => setConfirmationOpen(false)}
          continueAction={deleteServiceHandler}
        />
      ) : null}

      <TextCircle text={index + 1} />
      <SummaryFieldsContainer>
        <Typography>
          {serviceId === 'repair' ? 'Korjaus tai vianetsintä' : 'Huoltopalvelu'}
        </Typography>
        <Typography variant="caption">{`${serviceName}`}</Typography>
        <Typography variant="caption">{`${selectedService?.price} ${priceType}`}</Typography>
      </SummaryFieldsContainer>
      <div>
        <IconButton
          onClick={() => setConfirmationOpen(true)}
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2, marginRight: 0 }}
        >
          <Delete />
        </IconButton>
      </div>
    </Container>
  )
}

export default ServiceBlock
