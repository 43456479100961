import { Container, IconButton, Typography } from '@mui/material'
import { calculatePriceEstimate, SummaryFieldsContainer } from 'Components/common'
import { colors } from 'Theme/theme'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import EditMotorSummary from './EditMotorSummary'

const ServicesListing = styled.div`
  display: flex;
  justify-content: space-between;
`
type DevicesAndServicesBlockProps = {
  containsCustomDetails: boolean
}

const DevicesAndServicesBlock = ({ containsCustomDetails }: DevicesAndServicesBlockProps) => {
  const handleClick = () => {
    setOpen(!open)
  }
  const [open, setOpen] = useState(true)
  const { motors, availableMaintenanceServices, launchService } = useSelector(
    (state: RootState) => state.maintenance
  )

  const priceEstimate = calculatePriceEstimate({
    motors,
    availableMaintenanceServices,
    launchService
  })

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '8px',
        paddingBottom: '8px'
      }}
      disableGutters
    >
      <ServicesListing>
        <SummaryFieldsContainer disableGutters>
          <Typography
            variant="subtitle1"
            color={colors.mainBlack}
          >{`Laitteet ja palvelut`}</Typography>
          {containsCustomDetails ? null : (
            <Typography
              variant="body2"
              color={colors.gray}
            >{`Hinta-arvio ${priceEstimate} €`}</Typography>
          )}
        </SummaryFieldsContainer>
        <IconButton
          onClick={handleClick}
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2, marginRight: 0 }}
        >
          {open ? (
            <ExpandLess sx={{ color: colors.mainBlue }} />
          ) : (
            <ExpandMore sx={{ color: colors.mainBlue }} />
          )}
        </IconButton>
      </ServicesListing>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {motors.map(({ manufacturer, model, services, customMotorDetails }, motorIndex) => {
          return (
            <EditMotorSummary
              manufacturer={manufacturer}
              model={model}
              services={services}
              motorIndex={motorIndex}
              key={motorIndex}
              customMotorDetails={customMotorDetails}
            />
          )
        })}
      </Collapse>
    </Container>
  )
}

export default DevicesAndServicesBlock
