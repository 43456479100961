import { FC, Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loader from 'suspenseSetup'
import { useQuery } from '@apollo/client'
import { gql } from 'apollo-boost'

import {
  ContactDetails,
  Datepicker,
  EngineDetails,
  Onboarding,
  OrderSummary,
  RepairTroubleshooting,
  SelectManufacturer,
  SelectModel,
  SelectService,
  Summary,
  ThankYou,
  CustomMotor
} from 'Components/Maintenance'
import { useDispatch, useSelector } from 'react-redux'
import {
  initAdditionalServices,
  initMaintenanceServices,
  initManufacturersAndModels,
  initModelList
} from 'Features/Maintenance/maintenanceSlice'
import { CircularProgress } from '@mui/material'
import { RootState } from 'Store'

const getMaintenanceServices = gql`
  query fetchMaintenanceServices($id: ID!) {
    fetchDataview(id: $id)
  }
`
const getBrandsAndModels = gql`
  query fetchBrandsAndModels($id: ID!) {
    fetchDataview(id: $id)
  }
`
const getAdditionalServices = gql`
  query fetchAdditionalServices($id: ID!) {
    fetchDataview(id: $id)
  }
`

const App: FC = () => {
  const dispatch = useDispatch()
  const { manufacturersAndModels } = useSelector((state: RootState) => state.maintenance)

  const { loading: maintenanceServiceLoading, data: maintenanceServiceData } = useQuery(
    getMaintenanceServices,
    {
      variables: { id: process.env.REACT_APP_FETCH_MAINTENANCE_SERVICES_KEY }
    }
  )

  useEffect(() => {
    if (maintenanceServiceData) {
      dispatch(initMaintenanceServices(maintenanceServiceData.fetchDataview.data.records))
    }
  }, [maintenanceServiceData])

  const { loading: additionalServiceLoading, data: additionalServiceData } = useQuery(
    getAdditionalServices,
    {
      variables: { id: process.env.REACT_APP_FETCH_ADDITIONAL_SERVICES_KEY }
    }
  )

  useEffect(() => {
    if (additionalServiceData) {
      dispatch(initAdditionalServices(additionalServiceData.fetchDataview.data.records))
    }
  }, [additionalServiceData])

  const { loading: brandsAndModelsLoading, data: brandsAndModelsData } = useQuery(
    getBrandsAndModels,
    {
      variables: { id: process.env.REACT_APP_FETCH_BRANDS_AND_MODELS_KEY }
    }
  )

  useEffect(() => {
    if (brandsAndModelsData) {
      dispatch(initManufacturersAndModels(brandsAndModelsData.fetchDataview.data.models))
    }
  }, [brandsAndModelsData])

  const makeModelArray: string[] = []

  useEffect(() => {
    Object.entries(manufacturersAndModels).map(([manufacturer, models]) => {
      models.map(model => {
        makeModelArray.push(`${manufacturer} ${model}`)
      })
    })
    dispatch(initModelList(makeModelArray))
  }, [manufacturersAndModels])

  if (maintenanceServiceLoading || brandsAndModelsLoading || additionalServiceLoading) {
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: 'calc(50% - 20px)',
          transform: 'translate(-50%, -50%)',
          width: '300px',
          zIndex: 2000
        }}
      />
    )
  }

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <Route component={Onboarding} exact path="/" />
          <Route component={CustomMotor} path="/custom" />
          <Route component={SelectManufacturer} path="/manufacturer" />
          <Route component={SelectModel} path="/model" />
          <Route component={EngineDetails} path="/details" />
          <Route component={SelectService} path="/service" />
          <Route component={RepairTroubleshooting} path="/repair" />
          <Route component={Summary} path="/summary" />
          <Route component={Datepicker} path="/date" />
          <Route component={ContactDetails} path="/contact" />
          <Route component={OrderSummary} path="/ordersummary" />
          <Route component={ThankYou} path="/thankyou" />
        </Switch>
      </Router>
    </Suspense>
  )
}
export { App }
export default App
