import { Layout } from '@uniqore/module'
import RadioButtonGroup, { RadioButtonGroupSchema } from '@uniqore/module/RadioButtonGroup'
import { AltButton } from 'Components/AltButton'
import { AppBar } from 'Components/AppBar'
import { Heading } from 'Components/Heading'
import { changeMotorDetails, motorBase } from 'Features/Maintenance/maintenanceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'

const SelectManufacturer = () => {
  const { currentlySelectedMotor, manufacturersAndModels } = useSelector(
    (state: RootState) => state.maintenance
  )

  const { manufacturer } = useSelector(
    (state: RootState) => state.maintenance.motors[currentlySelectedMotor]
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const handleChange = (value: string) => {
    if (value !== manufacturer) {
      dispatch(changeMotorDetails({ ...motorBase, manufacturer: value }))
    } else {
      dispatch(changeMotorDetails({ manufacturer: value }))
    }
    history.push('/model')
  }

  const manufacturers = Object.keys(manufacturersAndModels)
  const radioButtons: RadioButtonGroupSchema[] = manufacturers.map(manufacturerName => {
    return {
      value: manufacturerName,
      text: manufacturerName
    }
  })

  return (
    <Layout top={<AppBar text="Takaisin" />}>
      <Heading
        title="Valitse valmistaja"
        subtitle={`Jos valmistaja ei ole listassa, niin valitse "Jokin muu, mikä" ja täytä valmistajan nimi.`}
      />
      <RadioButtonGroup
        value={manufacturer}
        onChange={handleChange}
        radioButtons={radioButtons}
        style={{ padding: 0 }}
      />
      <AltButton
        text="Jokin muu, mikä?"
        onClickEvent={() => history.push('/custom')}
        style={{ marginTop: 4 }}
      />
    </Layout>
  )
}

export default SelectManufacturer
