import styled from '@emotion/styled'
import { Sailing, Edit } from '@mui/icons-material'
import { Paper, Container, Typography, IconButton } from '@mui/material'
import { findSelectedService, findUnitServices } from 'Components/common'
import { changeMaintenanceDetails } from 'Features/Maintenance/maintenanceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import { colors } from 'Theme/theme'

const DeviceListingContainer = styled(Paper)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
`
const DeviceListingObject = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
`

type EditMotorSummaryProps = {
  manufacturer: string
  model: string
  services: Array<string>
  motorIndex: number
  customMotorDetails: string
}

const EditMotorSummary = ({
  manufacturer,
  model,
  services,
  motorIndex,
  customMotorDetails
}: EditMotorSummaryProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { availableMaintenanceServices } = useSelector((state: RootState) => state.maintenance)
  const { repairTroubleshooting } = useSelector(
    (state: RootState) => state.maintenance.motors[motorIndex]
  )

  const editMotorDetails = () => {
    dispatch(changeMaintenanceDetails({ currentlySelectedMotor: motorIndex }))
    dispatch(
      changeMaintenanceDetails({
        maintenanceFlow: { fromSummary: true }
      })
    )
    history.push('manufacturer')
  }
  const serviceDetails = findUnitServices(availableMaintenanceServices, model)

  const selectedServices = services.map(serviceId =>
    findSelectedService({
      availableMaintenanceServices: serviceDetails,
      serviceId
    })
  )
  const serviceNames = selectedServices.map(selectedService =>
    selectedService?.serviceName === 'repair'
      ? `${repairTroubleshooting.list.concat()}, ${repairTroubleshooting?.customDetails} `
      : selectedService?.serviceName
  )

  return (
    <DeviceListingContainer>
      <DeviceListingObject>
        <Sailing sx={{ height: '64px', width: '64px' }} />
        <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} disableGutters>
          <Typography>
            {manufacturer} {model}
          </Typography>
          {customMotorDetails.length == 0 ? (
            <>
              {serviceNames.map((serviceName, index) => {
                return (
                  <Typography key={index} variant="caption">
                    - {serviceName}
                  </Typography>
                )
              })}
            </>
          ) : (
            <Typography variant="caption">{customMotorDetails}</Typography>
          )}
        </Container>
      </DeviceListingObject>
      <IconButton
        onClick={() => editMotorDetails()}
        size="large"
        sx={{ height: '48px', marginRight: '0px' }}
      >
        <Edit sx={{ color: colors.mainBlue }} />
      </IconButton>
    </DeviceListingContainer>
  )
}

export default EditMotorSummary
