import { TextField } from '@mui/material'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { Heading } from 'Components/Heading'
import { changeMotorDetails } from 'Features/Maintenance/maintenanceSlice'
import { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import SelectButtonGroup, { SelectButtonGroupSchema } from '@uniqore/module/SelectButtonGroup'
import { Layout } from '@uniqore/module'

const RepairTroubleshooting = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentlySelectedMotor, availableAdditionalServices } = useSelector(
    (state: RootState) => state.maintenance
  )
  const { repairTroubleshooting } = useSelector(
    (state: RootState) => state.maintenance.motors[currentlySelectedMotor]
  )

  const selectButtons: SelectButtonGroupSchema[] = availableAdditionalServices?.map(
    addtionalService => {
      return {
        text: addtionalService.serviceName,
        value: addtionalService.serviceName
      }
    }
  )

  const handleFormat = (selectedAdditionalServices: string[]) => {
    dispatch(
      changeMotorDetails({
        repairTroubleshooting: {
          ...repairTroubleshooting,
          list: selectedAdditionalServices
        }
      })
    )
  }
  const handleTextFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(
      changeMotorDetails({
        repairTroubleshooting: {
          ...repairTroubleshooting,
          customDetails: event.target.value as string
        }
      })
    )
  }

  return (
    <Layout
      top={<AppBar text="Palvelunvalinta" />}
      bottom={<Button text="Siirry yhteenvetoon" onClickEvent={() => history.push('/summary')} />}
    >
      <Heading
        title="Korjaus tai vianetsintä"
        subtitle={`Vianetsintä tehdään tuntityönä 80€/h (max 4 h), minkä perusteella annetaan tarkempi korjausarvio. Hyväksytystä korjaustyöstä vähennetään vianetsintään käytetty aika. `}
      />
      <SelectButtonGroup
        values={repairTroubleshooting.list}
        onChange={handleFormat}
        selectButtons={selectButtons}
      />
      <TextField
        sx={{ width: '100%', margin: '8px 0px' }}
        label="Anna lisätietoja"
        value={repairTroubleshooting.customDetails}
        rows={4}
        multiline
        onChange={event => handleTextFieldChange(event)}
      />
    </Layout>
  )
}

export default RepairTroubleshooting
