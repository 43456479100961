import { Checkbox, Container, FormControlLabel, Link, Typography } from '@mui/material'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { calculateServiceDuration, findServiceDetails, LoaderDisabler } from 'Components/common'
import { Heading } from 'Components/Heading'
import { DevicesAndServicesBlock, EditableDataBlock } from 'Components/SummaryComponents'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import format from 'date-fns/format'
import { useEffect, useState } from 'react'
import Client from 'api/client'
import { FORM_RESULT, INGEST_FORM } from './Datepicker'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { colors } from 'Theme/theme'
import { Layout } from '@uniqore/module'

const OrderSummary = () => {
  const history = useHistory()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const {
    contactInfo: { firstName, lastName, phone, email, address, zipCode, city },
    maintenanceDate,
    availableMaintenanceServices,
    motors,
    launchService,
    scheduledMaintenance
  } = useSelector((state: RootState) => state.maintenance)
  const formattedMaintenanceDate = maintenanceDate
    ? format(maintenanceDate, 'dd.MM.yyy')
    : 'Ei valittua ajankohtaa'
  const [response, setResponse] = useState()

  const motorContainsCustomDetails = () => {
    if (motors.filter(motor => motor.customMotorDetails?.length > 0).length > 0) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (response === '200') {
      history.push('/thankyou')
    } else {
      if (response === '500') {
        toast.error(
          <>
            <Typography>Järjestelmässä tapahtui virhe, yritä myöhemmin uudelleen</Typography>
          </>
        )
      }
    }
  }, [response])

  const contactDetails = { firstName, lastName, phone, email, address, zipCode, city }

  const maxRetries = 20
  const dateForBackend = maintenanceDate ? format(maintenanceDate, 'yyyy-MM-dd') : undefined

  const workDuration = (
    calculateServiceDuration({ motors, availableMaintenanceServices }) * 60
  ).toFixed()

  const motorSide = (motorSide: string | undefined) => {
    if (motorSide) {
      switch (motorSide) {
        case 'left':
          return 'vasen'
        case 'center':
          return 'keskellä'
        case 'right':
          return 'oikea'
        default:
          return 'keskellä'
      }
    }
  }
  const motorDetails = motors.map(motor => {
    const motorServices = motor.services.map(motorService =>
      findServiceDetails(availableMaintenanceServices, motorService)
    )
    const serviceNames = motorServices.map(motorService => motorService?.serviceName)
    const servicePrice = motorServices.map(motorService => motorService?.price)
    const priceEstimate = servicePrice.reduce((partialSum, a) => (partialSum || 0) + (a || 0), 0)

    return {
      manufacturer: motor?.manufacturer || '-',
      model: motor?.model || '-',
      hours: motor?.hours || '-',
      year: motor?.year || '-',
      serial: motor?.serial || '-',
      side: motorSide(motor?.side) || '-',
      additionalDetails: motor?.additionalDetails || '-',
      customMotorDetails: motor?.customMotorDetails || '-',
      services: serviceNames.join(', ') || '-',
      repairServices: motor.repairTroubleshooting.list.join(', ') || '-',
      repairServiceDetails: motor.repairTroubleshooting.customDetails || '-',
      launchService: launchService ? true : false,
      scheduledMaintenance: scheduledMaintenance ? true : false,
      priceEstimate: priceEstimate
    }
  })

  const saveReservation = async (formId: string, i: number) => {
    const result = await Client.query({
      fetchPolicy: 'no-cache',
      query: FORM_RESULT,
      variables: {
        id: formId
      }
    })

    const responseState = result.data.formResult?.state || null
    const responseResult = result.data.formResult?.result?.status || null

    setResponse(responseResult)
    if (!responseResult || !String(responseResult) || responseState == 'WAITING') {
      setTimeout(() => {
        if (--i) saveReservation(formId, i)
      }, 500)
      return
    }
  }

  const [saveReservationSession, { loading }] = useMutation(INGEST_FORM, {
    client: Client,
    onCompleted(mutationResult) {
      const formId = mutationResult.ingestForm.result
      if (formId) {
        saveReservation(formId, maxRetries)
      } else {
        history.push('/thankyou')
      }
    }
  })
  const sendReservation = async () => {
    if (motorContainsCustomDetails()) {
      await saveReservationSession({
        variables: {
          id: process.env.REACT_APP_SAVE_CUSTOM_RESERVATION,
          form: {
            reservationLines: motorDetails,
            contactDetails: contactDetails
          }
        }
      })
    } else {
      await saveReservationSession({
        variables: {
          id: process.env.REACT_APP_SAVE_RESERVATION,
          form: {
            reservationDate: dateForBackend,
            formattedMaintenanceDate: formattedMaintenanceDate,
            workDuration: workDuration,
            reservationLines: motorDetails,
            contactDetails: contactDetails,
            launchService: launchService || false,
            scheduledMaintenance: scheduledMaintenance || false
          }
        }
      })
    }
  }

  return (
    <>
      {loading ? <LoaderDisabler /> : null}
      <Layout
        top={<AppBar text="Yhteystietoihin" />}
        bottom={
          <Button
            text="Tee tilaus"
            onClickEvent={() => sendReservation()}
            disabled={loading || acceptTerms !== true}
          />
        }
      >
        <Heading title="Yhteenveto tilauksesta" />
        <DevicesAndServicesBlock containsCustomDetails />
        {motorContainsCustomDetails() ? null : (
          <EditableDataBlock
            title={'Ajankohta'}
            captionTexts={[formattedMaintenanceDate]}
            editLocation={'date'}
          />
        )}

        <EditableDataBlock
          title={'Yhteystiedot'}
          captionTexts={[
            `${firstName} ${lastName}, ${phone},`,
            email,
            `${address}, ${zipCode} ${city}`
          ]}
          editLocation={'contact'}
        />
        <Container disableGutters>
          <FormControlLabel
            sx={{ width: '100%' }}
            control={
              <Checkbox
                value={acceptTerms}
                onChange={event => setAcceptTerms(event.target.checked)}
              />
            }
            label={
              <Typography
                variant="subtitle1"
                color={colors.mainBlack}
                sx={{ userSelect: 'none', marginLeft: '30px' }}
              >
                {`Hyväksyn `}
                <Link
                  rel="noopener noreferrer"
                  target="_blank"
                  href={'https://ramstedt.fi/tietosuojaseloste/'}
                >
                  tilausehdot ja tietosuojan.
                </Link>
              </Typography>
            }
          />
        </Container>
      </Layout>
    </>
  )
}

export default OrderSummary
