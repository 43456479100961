import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { colors } from 'Theme/theme'

type AltButtonProps = {
  text: string
  style?: React.CSSProperties
  onClickEvent: () => void
  icon?: ReactNode
}

const AltButtonContainer = styled.div``

const AltButton = ({ text, style, onClickEvent, icon }: AltButtonProps) => {
  return (
    <AltButtonContainer style={style}>
      <Button
        sx={{
          width: '100%',
          height: '41px',
          borderRadius: '100px'
        }}
        onClick={() => onClickEvent()}
      >
        {icon && icon}
        <Typography sx={{ fontSize: '14px' }} color={colors.mainBlue}>
          {text}
        </Typography>
      </Button>
    </AltButtonContainer>
  )
}

export { AltButton }
