import { createTheme } from '@mui/material/styles'
import { UqTheme, UqThemeOptions } from '@uniqore/theme'

export const colors = {
  mainBlack: 'rgba(0, 44, 56, 0.87)',
  mainOrange: '#ED6D04',
  mainBlue: '#00AFD1',
  white: '#fff',
  transparent: 'rgba(0,0,0,0)',
  gray: 'rgba(0, 44, 56, 0.6)',
  lightGray: '#C4CCCE'
}
const theme = createTheme({
  ...UqTheme,
  palette: {
    primary: {
      main: colors.mainBlack
    },
    secondary: {
      main: colors.mainBlue
    }
  },
  typography: {
    subtitle1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.00938em'
    },
    h5: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: '700',
      lineHeight: '28px'
    },
    h6: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: '600',
      lineHeight: '31px',
      letterSpacing: '0.15px'
    },
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '1.25px'
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '21px',
      letterSpacing: '0.25px'
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')
  },
  wrappers: {
    uqButton: {
      sx: {
        height: '48px',
        borderRadius: '100px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      }
    }
  }
} as UqThemeOptions)

export default theme
