import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@mui/styles'
import { CircularProgress, Theme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import { Close } from '@mui/icons-material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { useMediaQuery } from '@mui/material'

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaperPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  dialogPaperSecondary: {
    backgroundColor: theme.palette.secondary.main
  },
  appBarPrimaryColor: {
    backgroundColor: theme.palette.primary.main
  },
  appBarSecondaryColor: {
    backgroundColor: theme.palette.secondary.main
  },
  appBar: {
    height: '64px',
    position: 'initial'
  },
  toolbar: {
    paddingLeft: '16px',
    paddingRight: '0px',
    height: '64px'
  },
  closeIcon: {
    marginRight: theme.spacing(2)
  },
  input: {
    color: '#FFFFFF'
  },
  searchIcon: {
    flexGrow: 1
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
    borderRadius: '20px 20px 0px 0px',
    height: '70vh',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100% - 64px)'
    }
  },
  searchHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '55px',
    borderBottom: '1px solid rgba(29, 29, 27, 0.12)'
  },
  searchText: {
    color: 'rgba(29, 29, 27, 0.87)',
    padding: '0 16px'
  },
  searchResults: {
    overflow: 'scroll',
    marginBottom: '50px',
    height: 'calc(100% - 55px - 1px - 50px)', // header + border + button
    backgroundColor: '#FFFFFF',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  readyButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  spinner: {
    width: '200px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export interface Props {
  /**
   * The color of the component based on the theme that is used
   */
  color?: 'primary' | 'secondary'
  /**
   * Is search dialog open or closed
   */
  open: boolean
  /**
   * Value used in the search bar. Should be controlled by the onChange function
   */
  searchValue: string
  /**
   * Called on search term change
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value`.
   */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Search input field placeholder
   */
  inputPlaceholder?: string
  /**
   * Text displayed at the top of result window
   */
  resultsHeading?: string
  /**
   * Text that is displayed before results are shown
   */
  resultsPlaceholder?: string
  /**
   * If given, shown at the bottom of the results
   */
  readyButton?: React.ReactNode
  /**
   * Displayed list of results
   */
  searchResults?: React.ReactNode[]
  /**
   * Called on close button click
   */
  onClose?: () => void
  /**
   * If 'true', a spinner is shown
   */
  isSearching?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
  /**
   * Class attribute
   */
  className?: string
  /**
   * Determine the max-width of the component.
   * The component width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
}

const SearchDialog: React.FC<Props> = ({
  color = 'secondary',
  open,
  searchValue,
  onChange,
  readyButton,
  maxWidth,
  inputPlaceholder = 'Search...',
  resultsHeading = 'Results',
  resultsPlaceholder = 'Enter a search term',
  searchResults = [],
  onClose = () => {
    ;() => undefined
  },
  isSearching = false,
  style,
  className
}) => {
  const classes = useStyles()
  const theme: Theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      style={style}
      className={className}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      PaperProps={{
        className: clsx({
          [classes.dialogPaperPrimary]: color === 'primary',
          [classes.dialogPaperSecondary]: color === 'secondary'
        })
      }}
    >
      <AppBar
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarPrimaryColor]: color === 'primary',
          [classes.appBarSecondaryColor]: color === 'secondary'
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => onClose()}
            aria-label="close"
            className={classes.closeIcon}
          >
            <Close />
          </IconButton>
          <div className={classes.searchIcon}>
            <InputBase
              autoFocus
              fullWidth
              placeholder={inputPlaceholder}
              value={searchValue}
              onChange={onChange}
              classes={{
                input: classes.input
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          {/* <IconButton color="inherit">
            <Search />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <div className={classes.searchContainer}>
        <div className={classes.searchHeader}>
          <Typography className={classes.searchText} variant="subtitle1">
            {resultsHeading}
          </Typography>
        </div>
        {isSearching && (
          <div className={classes.spinner}>
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '70%',
                left: 'calc(50% - 20px)',
                bottom: '20%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                zIndex: 2000
              }}
            />
          </div>
        )}
        {searchResults.length === 0 ? (
          <Typography
            style={{ color: 'rgba(29, 29, 27, 0.6)', padding: '16px' }}
            variant="subtitle1"
          >
            {resultsPlaceholder}
          </Typography>
        ) : (
          <div className={classes.searchResults}>{searchResults}</div>
        )}
        <div className={classes.readyButton}>{readyButton}</div>
      </div>
    </Dialog>
  )
}

export default SearchDialog
