import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { store } from 'Store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { App } from './App'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'Theme'
import { ApolloProvider } from '@apollo/client'
import Client from 'api/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const render = () => {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={Client}>
            <App />
            <ToastContainer />
          </ApolloProvider>
        </ThemeProvider>
      </Provider>
    </StrictMode>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
