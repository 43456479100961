import { Container, TextField } from '@mui/material'
import { Layout } from '@uniqore/module'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { Heading } from 'Components/Heading'
import { changeMaintenanceDetails } from 'Features/Maintenance/maintenanceSlice'
import { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'

const ContactDetails = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    contactInfo: { firstName, lastName, phone, email, address, zipCode, city },
    contactInfo
  } = useSelector((state: RootState) => state.maintenance)

  const handleTextFieldChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    dispatch(
      changeMaintenanceDetails({
        contactInfo: { ...contactInfo, [field]: event.target.value as string }
      })
    )
  }
  const validateInformationForm = () => {
    if (firstName && lastName && phone && email && address && zipCode && city) {
      return false
    } else {
      return true
    }
  }
  return (
    <Layout
      top={<AppBar text="Ajanvalinta" />}
      bottom={
        <Button
          text="Yhteenvetoon"
          disabled={validateInformationForm()}
          onClickEvent={() => history.push('/ordersummary')}
        />
      }
    >
      <Heading title="Yhteystiedot" />
      <Container>
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={firstName}
          label="Etunimi"
          required
          autoComplete="cc-given-name"
          onChange={event => handleTextFieldChange(event, 'firstName')}
        />
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={lastName}
          label="Sukunimi"
          required
          autoComplete="cc-family-name"
          onChange={event => handleTextFieldChange(event, 'lastName')}
        />
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={phone}
          label="Puhelinnumero"
          required
          autoComplete="tel"
          onChange={event => handleTextFieldChange(event, 'phone')}
        />
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={email}
          label="Sähköposti"
          required
          autoComplete="email"
          onChange={event => handleTextFieldChange(event, 'email')}
        />
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={address}
          label="Postiosoite"
          required
          autoComplete="street-address"
          onChange={event => handleTextFieldChange(event, 'address')}
        />
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={zipCode}
          label="Postinumero"
          required
          autoComplete="postal-code"
          onChange={event => handleTextFieldChange(event, 'zipCode')}
        />
        <TextField
          sx={{ width: '100%', margin: '8px 0px' }}
          value={city}
          label="Kaupunki"
          required
          autoComplete="address-level2"
          onChange={event => handleTextFieldChange(event, 'city')}
        />
      </Container>
    </Layout>
  )
}

export default ContactDetails
