import styled from '@emotion/styled'
import { IconButton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { SearchDialog } from '.'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { colors } from 'Theme/theme'
import { changeMotorDetails, motorBase } from 'Features/Maintenance/maintenanceSlice'
import { useHistory } from 'react-router-dom'
import { Onboarding as UqOnboarding } from '@uniqore/module'
import { Button } from 'Components/Button'
import { AltButton } from 'Components/AltButton'
import ramstedtLogo from 'assets/images/Ramstedt-marine-black-300x74.png'

const ListItemContainer = styled.div`
  height: 32px;
  background-color: ${colors.mainBlue};
  padding: 16px;
  margin: 16px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  justify-content: space-between;
`
type ListItemProps = {
  name: string
  onSelection: () => void
}
const ListItem = ({ name, onSelection }: ListItemProps) => {
  return (
    <ListItemContainer>
      <Typography color={colors.white}>{name}</Typography>
      <IconButton
        size="large"
        edge="start"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={() => onSelection()}
      >
        <AddCircleOutlineIcon sx={{ fill: colors.white }} />
      </IconButton>
    </ListItemContainer>
  )
}
const Onboarding = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchResults, setSearchResults] = useState<Array<string | never>>([])
  const { modelList } = useSelector((state: RootState) => state.maintenance)
  const openSearch = () => {
    setSearchOpen(true)
  }
  const closeSearch = () => {
    setSearchOpen(false)
  }
  const selectMotor = (manufacturerAndModel: string) => {
    const manufacturerAndModelSplit = manufacturerAndModel.split(' ')
    dispatch(
      changeMotorDetails({
        ...motorBase,
        manufacturer: manufacturerAndModelSplit[0],
        model: manufacturerAndModelSplit[1]
      })
    )
    history.push('/details')
  }
  // updates search results
  useEffect(() => {
    if (searchOpen && modelList) {
      setSearchResults(modelList.filter(v => v.toLowerCase().includes(searchText.toLowerCase())))
      if (searchText === '') {
        setSearchResults([])
      }
    }
  }, [searchText, modelList])

  return (
    <>
      <UqOnboarding
        actionComponents={[
          <Button text={'Hae nimellä'} key={'primary'} onClickEvent={() => openSearch()}></Button>,
          <AltButton
            text={'Valitse katalogista'}
            key={'secondary'}
            onClickEvent={() => history.push('/manufacturer')}
          />
        ]}
        carouselItems={[
          {
            headline: (
              <Typography textAlign={'center'} variant={'h6'} color={colors.mainBlack}>
                Tervehdys
              </Typography>
            ),
            subtitle:
              'Aloita perämoottorien huolto- ja/tai korjaustilaus valitsemalla huollettava laite.',
            image: { src: ramstedtLogo }
          }
        ]}
      ></UqOnboarding>
      <SearchDialog
        open={searchOpen}
        onClose={closeSearch}
        searchValue={searchText}
        onChange={e => setSearchText(e.target.value)}
        inputPlaceholder="Etsi..."
        resultsHeading="Hakutulokset"
        resultsPlaceholder='Lisää esim. "Suzuki"'
        searchResults={searchResults.map((searchResult, index) => {
          return (
            <ListItem
              name={searchResult}
              key={index}
              onSelection={() => selectMotor(searchResult)}
            />
          )
        })}
      />
    </>
  )
}

export default Onboarding
