import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { AltButton } from 'Components/AltButton'
import { Button } from 'Components/Button'
import { colors } from 'Theme/theme'

const Blocker = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  border-radius: 20px;
  display: flex;
`
const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const DialogContainer = styled.div`
  margin: 32px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 8px;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`
const ButtonDiv = styled.div`
  display: flex;
  gap: 16px;
`

type ConfirmationModalProps = {
  closeModal: () => void
  continueAction: () => void
  title: string
  subtitle: string
}

export const ConfirmationModal = ({
  closeModal,
  continueAction,
  title,
  subtitle
}: ConfirmationModalProps) => {
  return (
    <Blocker>
      <RelativeContainer>
        <DialogContainer>
          <Typography
            lineHeight={'24px'}
            variant="h6"
            color={colors.mainBlack}
            style={{ marginBottom: '12px' }}
          >
            {title}
          </Typography>
          <Typography variant="subtitle1" fontSize={'14px'} color={colors.mainBlack}>
            {subtitle}
          </Typography>
          <ButtonContainer>
            <ButtonDiv>
              <AltButton style={{ flexBasis: '100%' }} text={'Peruuta'} onClickEvent={closeModal} />
              <Button style={{ flexBasis: '100%' }} text={'Kyllä'} onClickEvent={continueAction} />
            </ButtonDiv>
          </ButtonContainer>
        </DialogContainer>
      </RelativeContainer>
    </Blocker>
  )
}
