import { MaintenanceService, Motor } from 'Features/Maintenance/maintenanceSlice'
import { findSelectedService, findUnitServices } from '.'

type CalculatePriceEstimateProps = {
  motors: Array<Motor>
  availableMaintenanceServices: Array<MaintenanceService>
  launchService: boolean
}
export const calculatePriceEstimate = ({
  motors,
  availableMaintenanceServices,
  launchService
}: CalculatePriceEstimateProps) => {
  const liftingPrice = motors.length !== 0 ? motors.length * 30 : 30

  const motorServicePrices = motors.map(motor => {
    const motorServices = findUnitServices(availableMaintenanceServices, motor.model)
    const selectedServices = motor.services.map(service => {
      const selectedService = findSelectedService({
        availableMaintenanceServices: motorServices,
        serviceId: service
      })
      return selectedService
    })

    const servicePrices = selectedServices.map(service => {
      return service?.price || 0
    })
    return servicePrices
  })

  const servicePriceSum = motorServicePrices
    .map(motorServicePrice => motorServicePrice.reduce((partialSum, a) => partialSum + a, 0))
    .reduce((partialSum, a) => partialSum + a, 0)

  const liftingPriceAddition = launchService ? liftingPrice : 0

  return (servicePriceSum + liftingPriceAddition).toFixed(2)
}
