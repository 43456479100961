import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { colors } from 'Theme/theme'

const LoaderBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.transparent};
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translate(-50%, 0);
`
const LoaderDisabler = () => {
  return (
    <LoaderBackground>
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '70%',
          left: 'calc(50% - 20px)',
          bottom: '20%',
          transform: 'translate(-50%, -50%)',
          width: '300px',
          zIndex: 2000
        }}
      />
    </LoaderBackground>
  )
}

export default LoaderDisabler
