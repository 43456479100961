import logo from 'assets/images/logo.png'
import { FC } from 'react'

// loading component for suspense fallback
const Loader: FC = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
)

export default Loader
