import { MaintenanceService } from 'Features/Maintenance/maintenanceSlice'

export const findUnitServices = (
  availableServices: Array<MaintenanceService>,
  unitService: string
) => {
  return availableServices.filter(service => unitService === service.model)
}

export const findServiceDetails = (
  availableServices: Array<MaintenanceService>,
  serviceId: string
) => {
  return availableServices.find(service => serviceId === service.id)
}
