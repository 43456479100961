import styled from '@emotion/styled'
import { AddCircle } from '@mui/icons-material'
import { Container, Switch, Typography } from '@mui/material'
import { Layout } from '@uniqore/module'
import { AltButton } from 'Components/AltButton'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { calculatePriceEstimate } from 'Components/common'
import { SwitchContainer } from 'Components/common/SwitchContainer'
import { Heading } from 'Components/Heading'
import { MotorBlock, ServiceBlock } from 'Components/SummaryComponents'
import { addNewMotor, changeMaintenanceDetails } from 'Features/Maintenance/maintenanceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import { colors } from 'Theme/theme'

const SwitchTitle = styled.div`
  display: flex;
  flex-direction: column;
`
const ServiceContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
`

const Summary = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    currentlySelectedMotor,
    motors,
    launchService,
    scheduledMaintenance,
    availableMaintenanceServices
  } = useSelector((state: RootState) => state.maintenance)

  const liftingPrice = motors.length !== 0 ? motors.length * 30 : 30

  const addNewMotorEvent = () => {
    dispatch(addNewMotor())
    dispatch(changeMaintenanceDetails({ currentlySelectedMotor: currentlySelectedMotor + 1 }))
    history.push('manufacturer')
  }
  const containsRepairsOrTroubleshooting = () => {
    for (const motor of motors) {
      if (
        motor.repairTroubleshooting.list.length > 0 ||
        motor.repairTroubleshooting.customDetails
      ) {
        return true
      }
    }
    return false
  }

  const priceEstimate = calculatePriceEstimate({
    motors,
    availableMaintenanceServices,
    launchService
  })

  const allMotorsHaveServiceSelected = motors.every(motor => {
    if (motor.services.length > 0) {
      return true
    }
  })

  return (
    <Layout
      bottom={
        <Button
          text="Ajanvaraukseen"
          onClickEvent={() => history.push('/date')}
          disabled={!allMotorsHaveServiceSelected}
        />
      }
      top={<AppBar text="Palvelunvalinta" />}
    >
      <Heading
        title="Yhteenveto"
        subtitle={`Alla yhteenveto valituista palveluista. Tarkemman hinnan saat sähköpostitse tarkistettuamme tilauksesi.`}
      />
      {motors.map(({ manufacturer, model, hours, year, services }, motorIndex) => {
        return (
          <ServiceContainer key={motorIndex}>
            <MotorBlock engineSummary={{ manufacturer, model, hours, year }} index={motorIndex} />
            {services.map((service, index) => {
              return (
                <ServiceBlock
                  key={index}
                  serviceId={service}
                  motorIndex={motorIndex}
                  index={index}
                />
              )
            })}
          </ServiceContainer>
        )
      })}
      <AltButton
        icon={<AddCircle sx={{ marginRight: '10px', color: colors.mainBlue }} />}
        text="Lisää uusi moottori/palvelu"
        onClickEvent={() => addNewMotorEvent()}
        style={{ padding: '8px 0px 16px 0px' }}
      />
      <Heading title="Lisäpalvelut" subtitle={`Valitse tarvittavat lisäpalvelut`} />
      <Container sx={{ padding: '16px 0px 16px 0px' }} disableGutters>
        <SwitchContainer style={{ padding: '8px 0px 8px 0px' }}>
          <SwitchTitle>
            <Typography variant="subtitle1" color={colors.mainBlack}>
              Nosto- ja laskutarve
            </Typography>
            <Typography variant="body2" color={colors.gray}>
              +{liftingPrice},00 €
            </Typography>
          </SwitchTitle>
          <Switch
            checked={launchService}
            onChange={event =>
              dispatch(
                changeMaintenanceDetails({
                  launchService: event.target.checked
                })
              )
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </SwitchContainer>
        <SwitchContainer style={{ padding: '8px 0px' }}>
          <SwitchTitle>
            <Typography variant="subtitle1" color={colors.mainBlack}>
              Määräaikaishuolto
            </Typography>
            <Typography variant="body2" color={colors.gray}>
              Huoltosuunnitelman mukaan. Olen huollattanut laitteeni aiemmin myös Venetelakka
              Ramstedtilla.
            </Typography>
          </SwitchTitle>
          <Switch
            checked={scheduledMaintenance}
            onChange={event =>
              dispatch(
                changeMaintenanceDetails({
                  scheduledMaintenance: event.target.checked
                })
              )
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </SwitchContainer>
      </Container>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '8px',
          paddingBottom: '8px'
        }}
        disableGutters
      >
        <Typography variant="h6" color={colors.mainBlack} padding="8px 0px 16px 0px">
          Työarvio: {priceEstimate} €
          {containsRepairsOrTroubleshooting() || scheduledMaintenance ? '*' : ''}
        </Typography>
        {containsRepairsOrTroubleshooting() ? (
          <Typography
            variant="caption"
            align="center"
            color={colors.gray}
            sx={{ padding: '8px 0px' }}
          >
            *Tilauksesi sisältää korjaus- tai vianetsintätarpeita. Olemme yhteydessä tarkemman
            korjausarvion osalta. Työarvio sisältää arvonlisäveron.
          </Typography>
        ) : null}
        {scheduledMaintenance ? (
          <Typography
            variant="caption"
            align="center"
            color={colors.gray}
            sx={{ padding: '8px 0px' }}
          >
            *Tilauksesi sisältää määräaikaishuoltotöitä. Olemme yhteydessä tarkemman huoltoarvion
            osalta. Työarvio sisältää arvonlisäveron.
          </Typography>
        ) : null}
      </Container>
    </Layout>
  )
}

export default Summary
