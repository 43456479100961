import styled from '@emotion/styled'
import { Container } from '@mui/material'
import { AltButton } from 'Components/AltButton'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { findSelectedService, findUnitServices } from 'Components/common'
import { Heading } from 'Components/Heading'
import { SmallPrint } from 'Components/SmallPrint'
import { changeMotorDetails } from 'Features/Maintenance/maintenanceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import SelectButtonGroup, { SelectButtonGroupSchema } from '@uniqore/module/SelectButtonGroup'
import { Layout } from '@uniqore/module'

const ButtonWrapper = styled(Container)`
  display: flex;
  align-items: center;
`

const SelectService = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentlySelectedMotor, availableMaintenanceServices } = useSelector(
    (state: RootState) => state.maintenance
  )

  const { services, model } = useSelector(
    (state: RootState) => state.maintenance.motors[currentlySelectedMotor]
  )

  const handleFormat = (newServices: string[]) => {
    dispatch(
      changeMotorDetails({
        services: newServices
      })
    )
  }

  const forward = () => {
    if (services.includes('repair')) {
      history.push('/repair')
    } else {
      history.push('/summary')
    }
  }

  const unitServices = findUnitServices(availableMaintenanceServices, model)
  const selectedServices = services.map(serviceId =>
    findSelectedService({
      availableMaintenanceServices: unitServices,
      serviceId
    })
  )

  const selectButtons: SelectButtonGroupSchema[] = unitServices
    .map(unitService => {
      return {
        text: unitService?.serviceName,
        value: unitService?.id || '',
        metaText: `${unitService?.price}€`
      }
    })
    .filter(service => service.text !== '')
  selectButtons.push({
    text: 'Korjaus tai vianetsintä',
    value: 'repair',
    metaText: '80 € / h'
  })

  return (
    <Layout
      top={<AppBar text="Laitteen lisätiedot" />}
      bottom={
        <ButtonWrapper>
          <AltButton
            text="Takaisin"
            style={{ width: '50%', paddingRight: '8px' }}
            onClickEvent={() => history.goBack()}
          />
          <div style={{ width: '50%', paddingLeft: '8px' }}>
            <Button
              text="Eteenpäin"
              onClickEvent={() => forward()}
              disabled={selectedServices.length === 0}
            />
          </div>
        </ButtonWrapper>
      }
    >
      <Heading
        title="Valitse palvelu"
        subtitle={`Tarkemman hinnan saat sähköpostitse huoltohenkilökuntamme tarkistettua antamasi tiedot.`}
      />
      <SelectButtonGroup values={services} onChange={handleFormat} selectButtons={selectButtons} />

      <SmallPrint text="Ensihuolto 20 tuntia. Kausihuolto 100 tuntia tai kerran vuodessa. Määräaikaishuollot (esim. siipipyörän vaihto) valmistajan ohjeiden mukaisesti. " />
    </Layout>
  )
}

export default SelectService
