import { Typography } from '@mui/material'

import { colors } from 'Theme/theme'
import backgroundImage from 'assets/images/footer_bg.jpg'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { resetState } from 'Features/Maintenance/maintenanceSlice'
import { Onboarding } from '@uniqore/module'

const ThankYou = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetState())
  }, [])
  return (
    <Onboarding
      layoutProps={{
        background: `url(${backgroundImage})`,
        style: {
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }
      }}
      carouselItems={[
        {
          headline: (
            <Typography textAlign={'center'} variant={'h5'} color={colors.white}>
              Kiitos tilauksesta
            </Typography>
          ),
          subtitle: (
            <Typography textAlign={'center'} variant={'body1'} color={colors.white}>
              Olemme vastaanottaneet tilauksesi ja palaamme sinulle tilausvahvistuksen osalta
              seuraavan 48 h sisään. Jos sinulla herää jotain kysymyksiä, niin olethan yhteyksissä
              info@ramstedt.fi.
            </Typography>
          )
        }
      ]}
    ></Onboarding>
  )
}

export default ThankYou
