import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { Layout } from '@uniqore/module'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { SwitchContainer } from 'Components/common/SwitchContainer'
import { Heading } from 'Components/Heading'
import { changeMotorDetails } from 'Features/Maintenance/maintenanceSlice'
import { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import { colors } from 'Theme/theme'

const EngineDetails = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentlySelectedMotor } = useSelector((state: RootState) => state.maintenance)
  const { manufacturer, model, hours, year, serial, side, additionalDetails } = useSelector(
    (state: RootState) => state.maintenance.motors[currentlySelectedMotor]
  )
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false)

  const handleTextFieldChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    dispatch(changeMotorDetails({ [field]: event.target.value as string }))
  }

  return (
    <Layout
      top={<AppBar text="Laitteen valinta" />}
      bottom={
        <Button text="Siirry palvelunvalintaan" onClickEvent={() => history.push('/service')} />
      }
    >
      <Heading
        title={`${manufacturer} ${model}`}
        subtitle={`Lisätiedot auttavat tarjoamaan soveltuvia palveluita ja antamaan henkilökunnallemme esitietoja laitteesta.`}
      />
      <TextField
        sx={{ width: '100%' }}
        label="Moottorin ajotunnit"
        value={hours}
        helperText="Edellisen huollon jälkeiset ajotunnit"
        onChange={event => handleTextFieldChange(event, 'hours')}
      />
      <SwitchContainer>
        <Typography variant="subtitle1" color={colors.mainBlack}>
          Haluan antaa tarkempia lisätietoja
        </Typography>
        <Switch
          checked={showAdditionalDetails}
          onChange={event => setShowAdditionalDetails(event.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </SwitchContainer>
      {showAdditionalDetails ? (
        <>
          <TextField
            sx={{ width: '100%', margin: '8px 0px' }}
            value={year}
            label="Vuosimalli"
            onChange={event => handleTextFieldChange(event, 'year')}
          />
          <TextField
            sx={{ width: '100%', margin: '8px 0px' }}
            value={serial}
            label="Sarjanumero"
            onChange={event => handleTextFieldChange(event, 'serial')}
          />
          <FormControl fullWidth sx={{ margin: '8px 0px' }}>
            <InputLabel id="engine-side-select-label">Moottorin puoli</InputLabel>
            <Select
              labelId="engine-side-select-label"
              value={side}
              label="Moottorin puoli"
              onChange={event => handleTextFieldChange(event, 'side')}
            >
              <MenuItem value={'right'}>Oikea</MenuItem>
              <MenuItem value={'left'}>Vasen</MenuItem>
              <MenuItem value={'center'}>Keskellä</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ width: '100%', margin: '8px 0px' }}
            label="Vapaat lisätiedot"
            value={additionalDetails}
            rows={4}
            multiline
            onChange={event => handleTextFieldChange(event, 'additionalDetails')}
          />
        </>
      ) : null}
    </Layout>
  )
}

export default EngineDetails
