import { SelectChangeEvent, TextField } from '@mui/material'
import { Layout } from '@uniqore/module'
import { AppBar } from 'Components/AppBar'
import { Button } from 'Components/Button'
import { Heading } from 'Components/Heading'
import { changeMotorDetails } from 'Features/Maintenance/maintenanceSlice'
import { ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'

const CustomMotor = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { currentlySelectedMotor } = useSelector((state: RootState) => state.maintenance)
  const { customMotorDetails } = useSelector(
    (state: RootState) => state.maintenance.motors[currentlySelectedMotor]
  )

  const handleTextFieldChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    field: string
  ) => {
    dispatch(changeMotorDetails({ [field]: event.target.value as string }))
  }

  return (
    <Layout
      top={<AppBar text="Tuotevalinta" />}
      bottom={<Button text="Yhteystietoihin" onClickEvent={() => history.push('/contact')} />}
    >
      <Heading
        title="Lisätiedot"
        subtitle={`Lisää tähän vapaaseen kenttään lisätietoja palvelutarpeesta.`}
      />
      <TextField
        sx={{ width: '100%', margin: '8px 0px' }}
        label="Esim. vuosimalli, vika, palvelutarve, muut tiedot..."
        value={customMotorDetails}
        rows={4}
        multiline
        onChange={event => handleTextFieldChange(event, 'customMotorDetails')}
      />
    </Layout>
  )
}

export default CustomMotor
