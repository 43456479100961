import { Container, Typography } from '@mui/material'
import { colors } from 'Theme/theme'

type SmallPrintProps = {
  text: string
}
const SmallPrint = ({ text }: SmallPrintProps) => {
  return (
    <Container sx={{ textAlign: 'center', padding: '8px 16px' }}>
      <Typography variant="caption" sx={{ color: colors.gray }}>
        {text}
      </Typography>
    </Container>
  )
}

export default SmallPrint
