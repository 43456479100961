import React from 'react'
import Container from '@mui/material/Container'

export interface Props {
  /**
   * @ignore
   */
  children: NonNullable<React.ReactNode>
  /**
   * Determine the max-width of the container.
   * The container width grows with the size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  /**
   * Set the max-width to match the min-width of the current breakpoint.
   * This is useful if you'd prefer to design for a fixed set of sizes
   * instead of trying to accommodate a fully fluid viewport.
   * It's fluid by default.
   */
  fixed?: boolean
  /**
   * Style attribute
   */
  style?: React.CSSProperties
}

const Wrapper: React.FC<Props> = ({ maxWidth, children, fixed = false, style }) => {
  let setMaxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false = false
  if (maxWidth) {
    setMaxWidth = maxWidth
  }

  return (
    <React.Fragment>
      {setMaxWidth ? (
        <Container style={style} maxWidth={setMaxWidth} fixed={fixed} disableGutters>
          {children}
        </Container>
      ) : (
        <div style={style}>{children}</div>
      )}
    </React.Fragment>
  )
}

export default Wrapper
