import { Edit } from '@mui/icons-material'
import { Container, IconButton, Typography } from '@mui/material'
import { SummaryFieldsContainer } from 'Components/common'
import { changeMaintenanceDetails } from 'Features/Maintenance/maintenanceSlice'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { colors } from 'Theme/theme'

type EditableDataBlockProps = {
  title: string
  captionTexts: Array<string>
  editLocation: string
}

const EditableDataBlock = ({ title, captionTexts, editLocation }: EditableDataBlockProps) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const editDetails = () => {
    dispatch(
      changeMaintenanceDetails({
        maintenanceFlow: { fromSummary: true }
      })
    )
    history.push(`/${editLocation}`)
  }

  return (
    <Container
      sx={{ display: 'flex', alignItems: 'center', paddingTop: '8px', paddingBottom: '8px' }}
      disableGutters
    >
      <SummaryFieldsContainer disableGutters>
        <Typography variant="subtitle1" color={colors.mainBlack}>
          {title}
        </Typography>
        {captionTexts?.map((captionText, index) => {
          return (
            <Typography
              variant="body2"
              color={colors.gray}
              key={index}
            >{`${captionText}`}</Typography>
          )
        })}
      </SummaryFieldsContainer>
      <div>
        <IconButton
          onClick={() => editDetails()}
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 2, marginRight: 0 }}
        >
          <Edit sx={{ color: colors.mainBlue }} />
        </IconButton>
      </div>
    </Container>
  )
}

export default EditableDataBlock
